import React from "react";
import {UserList} from "../../components/admin/UserList";
import {TokenInformation} from "../../components/Authentication";
import {Heading, List} from "../../components/Typography";
import {Link} from "react-router-dom";

export function AdminRoute({}) {
    return <div className="max-w-full">
        <Heading>Administrative actions</Heading>
        <List>
            <Link to="/admin/users">Manage users</Link>
        </List>
        <TokenInformation title="User tokens"/>
    </div>;
}

export function ManageUsersRoute() {
    return <div className="max-w-full">
        <Heading>Manage Users</Heading>
        <UserList className="mt-8"/>
    </div>
}