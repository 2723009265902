import React from 'react';
import {cc} from "./Typography";

export function As({user, className, children}) {
    return <div className={cc(className, "border border-soc-border shadow rounded w-full")}>
        <div className="px-4 py-2 bg-soc-border text-white">As: {user}</div>
        <div className="p-4">
            {children}
        </div>
    </div>
}
