import React from "react";
import {debug} from "../services/Debugger";

// className concat
export function cc(dominantClassName, ...className) {
    return [...className, dominantClassName].join(" ");
}

export function Heading({className, children}) {
    return <h1 className={cc("text-2xl font-medium mt-2 py-3", className)}>{children}</h1>;
}

export function Subheading({className, children}) {
    return <h2 className={cc("text-xl font-medium mt-1 py-2", className)}>{children}</h2>;
}

export function Paragraph({className, children}) {
    return <p className={cc("mb-0.5 py-1", className)}>{children}</p>;
}

export function Quote({className, children}) {
    return <blockquote className={cc("italic border-l-4 border-soc-grey pl-6 mb-0.5 py-1", className)}>{children}</blockquote>;
}

export function Emphasize({className, children}) {
    return <em className={cc("font-bold", className)}>{children}</em>;
}

export function Italicize({className, children}) {
    return <i className={cc("italic", className)}>{children}</i>;
}

export function List({className, ordered = false, children}) {
    const style = "mb-0.5 pl-8 py-1";

    if (ordered) return <ol className={cc("list-decimal", style, className)}>{wrapChildren(children)}</ol>;
    return <ul className={cc("list-disc", style, className)}>{wrapChildren(children)}</ul>;

    function wrapChildren(children) {
        return asArray(children)
            .filter(c => typeof c !== 'string' || c.trim().length)
            .map((child, index) => {
            if (child.type === 'li') return child;
            return <li key={child.key || `item-${index}`}>{child}</li>;
        })
    }

    function asArray(children) {
        if (Array.isArray(children)) return children;
        return [children];
    }
}

export function Input(props) {
    const style = "bg-soc-face rounded p-2";

    const {onChange, className, multiline, ...remainingProps} = props;

    if (!multiline)
        return <input onChange={e => onChange(e.target.value)}
                                  className={cc(style, className)}
                                  {...remainingProps} />

    return <textarea onChange={e => onChange(e.target.value)}
                     className={cc(style, className)}
                     {...remainingProps} />
}