import React, {useState} from "react";
import {cc, Heading, List, Subheading} from "../Typography";
import {gql, useMutation, useQuery} from "@apollo/client";
import {Center} from "../Center";
import {GraphQlError} from "../GraphQLError";
import {Timestamp} from "../Timestamp";
import {allUserOnboardingFields, ONBOARDING_ASSESSMENT_ID} from "../OnboardingAssessment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBadgeCheck,
    faEnvelopeCircleCheck,
    faUserCrown
} from "@fortawesome/pro-regular-svg-icons";
import {faTriangleExclamation} from "@fortawesome/pro-solid-svg-icons";
import {debug} from "../../services/Debugger";
import {buttonStyle} from "../../utils/styles";
import {Link} from "react-router-dom";
import {Tooltip} from "../Tooltip";

export const allUserFields = gql`
    ${allUserOnboardingFields}
    fragment AllUserFields on User {
        id
        superuser
        createdAt
        onboarding {
            ...AllUserOnboardingFields
        }
    }
`;

const GET = gql`
    ${allUserFields}
    query {
        userList {
            ...AllUserFields
        }
    }
`;

const followupOnboarding = gql`
    ${allUserFields}
    mutation RecordFollowUpUserOnboarding($id: String!) {
        recordFollowUpUserOnboarding(id: $id) {
            ...AllUserFields
        }
    }
`;

export function UserList({className}) {
    const {
        loading,
        error,
        data
    } = useQuery(GET);

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error} />;

    return <table className={cc("max-w-full", className)}>
        <thead className="max-w-full">
            <tr className="p-2 border-b border-gray-200">
                <th className="p-2">User name</th>
                <th className="p-2">Superuser</th>
                <th className="p-2">First logged in</th>
                <th className="p-2">Onboarding status</th>
                <th className="p-2">Followup status</th>
                <th className="p-2">Actions</th>
            </tr>
        </thead>
        <tbody>
            { data.userList.map(user => <UserRow key={user.id} user={user} />)}
        </tbody>
    </table>
}

function UserRow({user}) {
    const [followUpCompleted, setFollowUpCompleted] = useState(user.onboarding?.followedUpAt);

    const onboardingCompleted = user.onboarding?.completedAt;

    return <tr>
        <td className="p-2">{user.id}</td>
        <td className="p-2">{user.superuser && <FontAwesomeIcon icon={faUserCrown}/>}</td>
        <td className="p-2"><Timestamp date={user.createdAt}/></td>
        <td className="p-2">{onboardingCompleted && <Timestamp date={onboardingCompleted}/>}</td>
        <td className="p-2">{followUpCompleted && <Timestamp date={followUpCompleted}/>}</td>
        <td className="p-2">
            {onboardingCompleted && !followUpCompleted && <FollowUpAction user={user} update={setFollowUpCompleted} />}
            <CoachAction user={user} />
        </td>
    </tr>;
}

function FollowUpAction({user, update}) {
    const [recordFollowUp, {loading, error}] = useMutation(followupOnboarding);

    if (loading) return <FontAwesomeIcon icon="spinner" spin />;
    if (error) return <span className="has-tooltip">
        <FontAwesomeIcon icon={faTriangleExclamation} className="text-red-600" />
        <Tooltip>Submission error! {error.message}</Tooltip>
    </span>;

    function handleClick() {
        recordFollowUp({variables: { id: user.id }})
            .then(d => {update(d.recordFollowUpUserOnboarding.onboarding?.followUpAt)})
            .catch((reason) => debug(JSON.stringify(reason)));
    }

    return <button className={buttonStyle().className} onClick={handleClick}>
        <FontAwesomeIcon icon={faEnvelopeCircleCheck} />
    </button>;
}

function CoachAction({user}) {
    return <Link className={buttonStyle().className}
                 to={`/admin/as/${user.id}/assessments/${ONBOARDING_ASSESSMENT_ID}/result`}>
        Coach
    </Link>;
}