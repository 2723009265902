import React, {useState} from "react";
import {cc, Input, Paragraph, Subheading} from "./Typography";
import {buttonStyle} from "../utils/styles";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {Center} from "./Center";
import {GraphQlError} from "./GraphQLError";
import {Timestamp} from "./Timestamp";
import Markdown from "react-markdown";
import {allTeamInsightFields} from "./TeamSummary";

const allAdviceFields = gql`
  fragment AllAdviceFields on Advice {
    name
    summary
    createdAt
    source
  }
`;

const allGenerationMetadataFields = gql`
  fragment AllGenerationMetadataFields on GenerationMetadata {
    model
    generationCount
    errors
    promptTokenCount
    generationTokenCount
  }
`

const GET_TEAM_INSIGHT_LIST = gql`
  ${allTeamInsightFields}
  query getTeamInsightsForUser($userId: String!) {
      teamInsightListForUser(user: $userId) {
          ...AllTeamInsightFields
      }
  }
`;

const RECORD_TEAM_INSIGHT = gql`
  ${allTeamInsightFields}
  mutation recordTeamInsight($userId: String!, $summary: String!) {
    recordTeamInsight(id: $userId, summary: $summary) {
      ...AllTeamInsightFields
    }
  }
`;

const GENERATE_TEAM_INSIGHT = gql`
  ${allTeamInsightFields}
  ${allGenerationMetadataFields}
  query generateTeamInsightForUser($userId: String!) {
    generateTeamInsightForUser(id: $userId) {
      insight {
        ...AllTeamInsightFields
      }
      metadata {
        ...AllGenerationMetadataFields
      }
    }
  }
`;

const GENERATE_TEAM_ADVICE = gql`
  ${allAdviceFields}
  ${allGenerationMetadataFields}
  query generateAdviceForUser($userId: String!) {
    generateAdviceForUser(id: $userId) {
      advice {
        ...AllAdviceFields
      }
      metadata {
        ...AllGenerationMetadataFields
      }
    }
  }
`;

export function CoachConsole({user, className}) {
  const {loading, error, data} = useQuery(GET_TEAM_INSIGHT_LIST, {variables: {userId: user}});

  if (loading) return <Center><p>Loading...</p></Center>;
  if (error) return <GraphQlError error={error} />;

  return <div className={className}>
    <Subheading>Your insights</Subheading>
    <TeamInsights insights={data.teamInsightListForUser} />
    <RecordTeamInsights user={user}/>

    <Subheading>Record your advice</Subheading>
    <RecordAdvice user={user}/>
  </div>;
}

function TeamInsights({insights}) {
  const [selected, setSelected] = useState(insights && insights.length > 0 ? insights[0].createdAt : null);

  return <>
    <select id="selected-insight" value={selected} onChange={e => setSelected(e.target.value)} className="w-full">
      {insights && insights.map(i => <option key={i.createdAt} value={i.createdAt}><Timestamp ts={i.createdAt} /></option>)}
    </select>
    <Insight insight={insights.find(i => i.createdAt === selected)} />
  </>;

  function Insight({insight}) {
    return insight && <>
      <Subheading>By {insight.source}:</Subheading>
      <Markdown components={{
        p(props) {
          const {node, ...rest} = props;
          return <Paragraph {...rest} />;
        }
      }}>{insight.summary}</Markdown>
    </>
  }
}

function RecordTeamInsights({user}) {
  const [generateInsight] = useLazyQuery(GENERATE_TEAM_INSIGHT);
  const [recordInsight, {loading, error}] = useMutation(RECORD_TEAM_INSIGHT);
  const [summary, setSummary] = useState('');
  const [metadata, setMetadata] = useState(null);

  if (loading) return 'Submitting';
  if (error) return `Submission error! ${error.message}`;

  function handleSubmit(event) {
    event.preventDefault();
    recordInsight({ variables: { userId: user, summary: summary } });
  }

  function handleGetMeStarted(event) {
    event.preventDefault();
    generateInsight({
      variables: {userId: user},
      onCompleted: d => {
        setSummary(d.generateTeamInsightForUser.insight.summary);
        setMetadata(d.generateTeamInsightForUser.metadata);
      },
      onError: e => alert("Unable to generate insights: " + e.message)});
  }

  return <>
    <Input className="w-full" multiline onChange={setSummary} value={summary} />
    {metadata && <GenerationMetadata metadata={metadata} />}
    <div className="text-right">
      <button className={cc("mr-1", buttonStyle().className)} onClick={handleGetMeStarted}>Get me started</button>
      <button className={buttonStyle().className} onClick={handleSubmit}>Save new insight</button>
    </div>
  </>;
}

function RecordAdvice({user}) {
  const [generateAdvice] = useLazyQuery(GENERATE_TEAM_ADVICE);
  const [adviceList, setAdviceList] = useState([]);
  const [metadata, setMetadata] = useState(null);

  function handleGetMeStarted(event) {
    event.preventDefault();
    generateAdvice({
      variables: {userId: user},
      onCompleted: d => {
        setAdviceList(d.generateAdviceForUser.advice);
        setMetadata(d.generateAdviceForUser.metadata);
      },
      onError: e => alert("Unable to generate advice: " + e.message)});
  }

  return <>
    {adviceList && adviceList.map(a => <Input key={a.name} className="w-full" multiline value={`**${a.name}**: ${a.summary}`} />)}
    {metadata && <GenerationMetadata metadata={metadata} />}
    <div className="text-right">
      <button className={cc("mr-1", buttonStyle().className)} onClick={handleGetMeStarted}>Get me started</button>
    </div>
  </>;
}

function GenerationMetadata({metadata}) {
  return <div className="text-xs mb-1">
    Model: {metadata.model}, #{metadata.generationCount} generations<br/>
    Tokens: {metadata.promptTokenCount} prompt/{metadata.generationTokenCount} generation
  </div>
}