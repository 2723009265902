import React, {useEffect, useState} from "react";
import {gql, useApolloClient, useQuery} from "@apollo/client";
import {Center} from "../components/Center";
import {GraphQlError} from "../components/GraphQLError";
import {allBehaviourObservationFields, BehaviourObservation} from "../components/BehaviourObservation";
import {allAssessmentFields, allCapabilityFields} from "../components/AssessmentDetails";
import {useParams} from "react-router-dom";
import {Timestamp} from "../components/Timestamp";
import {ProgressBar} from "../components/ProgressBar";
import {cc, Heading, Paragraph, Subheading} from "../components/Typography";
import {rememberAssessmentResults} from "../services/CachedAssessmentResults";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion} from "@fortawesome/pro-regular-svg-icons";
import {Tooltip} from "../components/Tooltip";
import {As} from "../components/As";
import {CoachConsole} from "../components/CoachConsole";

const allResultSnapshotFields = gql`
    ${allBehaviourObservationFields}
    ${allCapabilityFields}
    fragment AllResultSnapshotFields on AssessmentResultSnapshot {
        date
        observationList {
            ...AllBehaviourObservationFields
        }
        capabilityScoreList {
            capability {
                ...AllCapabilityFields
            }
            score {
                actual
                actualMaximum # potential maximum based on answered questions
            }
        }
    }
`;

export const GET_ASSESSMENT_RESULT_BY_ID = gql`
    ${allResultSnapshotFields}
    ${allAssessmentFields}
    query getAssessmentResult($assessmentId: ID!) {
        assessmentResult(id: $assessmentId) {
            assessment {
                ...AllAssessmentFields
            }
            current { # ordered by date from old to new
                ...AllResultSnapshotFields
            }
        }
    }
`;

export const GET_ASSESSMENT_RESULT_WITH_HISTORY_BY_ID = gql`
    ${allResultSnapshotFields}
    ${allAssessmentFields}
    query getAssessmentResult($assessmentId: ID!) {
        assessmentResult(id: $assessmentId) {
            assessment {
                ...AllAssessmentFields
            }
            history { # ordered by date from old to new
                ...AllResultSnapshotFields
            }
        }
    }
`;

export const GET_ASSESSMENT_RESULT_WITH_HISTORY_FOR_USER_BY_ID = gql`
    ${allResultSnapshotFields}
    ${allAssessmentFields}
    query getAssessmentResultForUser($assessmentId: ID!, $userId: String!) {
        assessmentResultForUser(id: $assessmentId, user: $userId) {
            assessment {
                ...AllAssessmentFields
            }
            history { # ordered by date from old to new
                ...AllResultSnapshotFields
            }
        }
    }
`;

export function AssessmentResultRoute() {
    const client = useApolloClient();

    const {id} = useParams();
    const {loading, error, data} = useQuery(GET_ASSESSMENT_RESULT_WITH_HISTORY_BY_ID, {variables: {assessmentId: id}});

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error}/>;

    rememberAssessmentResults(client, data.assessmentResult);

    return <AssessmentResult result={data.assessmentResult}/>
}

export function AssessmentResultForUserRoute() {
    const {id, user} = useParams();
    const {loading, error, data} = useQuery(GET_ASSESSMENT_RESULT_WITH_HISTORY_FOR_USER_BY_ID, {variables: {assessmentId: id, userId: user}});

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error}/>;

    return <div className="w-full flex flex-col lg:flex-row">
        <As user={user} className="basis-3/4">
            <AssessmentResult result={data.assessmentResultForUser}/>
        </As>
        <CoachConsole user={user} className="basis-1/4 m-2"/>
    </div>
}

function AssessmentResult({className, result}) {
    const [snapshot, setSnapshot] = useState();

    if (!result.history.length)
        return <div>
            No observations recorded yet.
        </div>;

    return <div className={cc("w-full", className)}>
        <Heading>Your results: {result.assessment.name}</Heading>
        <SnapshotSlider snapshots={result.history} setSnapshot={setSnapshot}/>
        <AssessmentResultDiagram snapshot={snapshot} className="mt-4"/>
        <Subheading className="mt-8">Observations</Subheading>
        <div>
            {snapshot && snapshot.observationList.map(o => <BehaviourObservation key={o.behaviour.id} hideSource
                                                                                 observation={o}/>)}
        </div>
    </div>
}

function SnapshotSlider({
                            snapshots, setSnapshot = (snapshot) => {
    }
                        }) {
    const [snapshotDate, setSnapshotDate] = useState();

    useEffect(() => {
        if (snapshots.length) selectSnapshot(dates.slice(-1)[0]);
    }, [snapshots]);

    const dates = []
    const snapshotMap = {}
    snapshots.forEach(s => {
        const date = new Date(s.date);
        dates.push(date); // preserve order
        snapshotMap[date] = s;
    });

    function selectSnapshot(date) {
        const selectedSnapshotDate = dates.filter(d => d <= date).slice(-1)[0];
        setSnapshotDate(selectedSnapshotDate);
        setSnapshot(snapshotMap[selectedSnapshotDate]);
    }

    const startTime = dates[0].getTime();
    const endTime = dates.slice(-1)[0].getTime();

    return <div>
        <Paragraph className="text-black/50"><FontAwesomeIcon icon={faCircleQuestion} className="pr-2"/>Move the slider along the timeline above to see how your team assessment changes over time.</Paragraph>
        <div className="flex justify-between -mb-5 mt-1 border-x-2 px-3 pb-3 border-black">
            <div><Timestamp date={snapshots[0].date}/></div>
            <div><Timestamp date={snapshots.slice(-1)[0].date}/></div>
        </div>
        <div className="w-full border-x-[10px] border-transparent box-border relative -z-10">
            {dates.slice(1, -1).map(d => <div className="h-2 absolute mt-2 top-0 left-0 border-r-2 border-black"
                                              style={{width: `${position(d)}%`}}/>)}
        </div>
        <input type="range"
               value={snapshotDate && snapshotDate.getTime()}
               min={startTime} max={endTime}
               className="w-full h-1 accent-soc-cheeks appearance-none bg-gray-100"
               onChange={(e => selectSnapshot(e.target.value))}/>
    </div>;

    function position(date) {
        return 100 * (date.getTime() - startTime) / (endTime - startTime);
    }
}

function AssessmentResultDiagram({className, snapshot}) {
    return snapshot && <div className={className}>
        Selected date: <span className="font-bold"><Timestamp date={snapshot.date}/></span>
        <Paragraph className="text-black/50"><FontAwesomeIcon icon={faCircleQuestion} className="pr-2"/>Hover over the
            progress bar to learn more about your score.</Paragraph>

        <div className="md:grid md:grid-cols-3 gap-4">
            <div className="pt-2 col-span-2">
                {snapshot.capabilityScoreList.map(c => <CapabilityProgress key={c.capability.name} capability={c.capability}
                                                                           score={c.score}/>)}
            </div>
            <AssessmentResultLegend className="mt-6"/>
        </div>
    </div>;
}

function CapabilityProgress({capability, score}) {
    const possibleRating = percentage(capability.minimum, capability.maximum, score.actualMaximum);
    const rating = percentage(capability.minimum, capability.maximum, score.actual);
    
    // TODO: Use progress bar colours to add visual cues
    return <div>
        {capability.name}
        <ProgressBar min={capability.minimum} max={capability.maximum} actual={score.actual}
                     potential={score.actualMaximum} className="has-tooltip">
            <Tooltip>
                {possibleRating < 0.6 && <div>Socrates needs more observations to provide a more confident result.</div>}
                {rating < 0.3 && <div>There is work to be done, you would benefit from some significant investment in this area.</div>}
                {rating >= 0.3 && rating < 0.6 && <div>There is room for improvement.</div>}
                {rating >= 0.6 && rating < 0.9 && <div>You are on the right track, keep on improving.</div>}
                {rating >= 0.9 && <div>Great job! Don't let this category slip as you improve the others</div>}
            </Tooltip>
        </ProgressBar>
    </div>

    function percentage(min, max, value) {
        return (value - min) / (max  - min);
    }
}

function AssessmentResultLegend({className}) {
    return <div className={cc(className, "flex flex-1 text-black/50")}>
        <FontAwesomeIcon icon={faCircleQuestion} className="pt-2 pr-2"/>
        <div className="text-sm">
            <Paragraph>Every assessment model rates your team based on the observations you registered.</Paragraph>
            <Paragraph>If your team scores near to 100% for all categories, this model cannot help you improve anymore. Do not allow the score to regress.</Paragraph>
        </div>
    </div>
}
