import {allBehaviourFields, Behaviour} from "./Behaviour";
import {cc, Paragraph} from "./Typography";
import React from "react";
import {gql} from "@apollo/client";
import {Timestamp} from "./Timestamp";

export const allBehaviourObservationFields = gql`
    ${allBehaviourFields}
    fragment AllBehaviourObservationFields on BehaviourObservation {
        behaviour {
            ...AllBehaviourFields
        }
        user
        isPresent
        createdAt
    }
`;

export const getBehaviourObservationList = gql`
    ${allBehaviourObservationFields}
    query {
        behaviourObservationList {
            ...AllBehaviourObservationFields
        }
    }
`;

export function BehaviourObservation({className, observation, hideSource}) {
    return <div className={cc(className, "my-1")}>
        <Behaviour behaviour={observation.behaviour} hideSource={hideSource} poll={observation.isPresent}/>
        <Paragraph className="text-right">
            <Timestamp ts={observation.createdAt}/>
        </Paragraph>
    </div>;
}

