import React from "react";
import {List, Paragraph, Subheading} from "./Typography";
import {gql, useQuery} from "@apollo/client";
import {Center} from "./Center";
import {GraphQlError} from "./GraphQLError";
import {Timestamp} from "./Timestamp";
import Markdown from "react-markdown";

export const allTeamInsightFields = gql`
    fragment AllTeamInsightFields on TeamInsight {
        summary
        createdAt
        source
    }
`;

const QUERY = gql`
    ${allTeamInsightFields}
    query {
        latestTeamInsight {
            ...AllTeamInsightFields
        }
    }
`;

export function TeamSummaryQuery({className}) {
  const {loading, error, data} = useQuery(QUERY);

  if (loading) return <Center><p>Loading...</p></Center>;

  if (error) return <GraphQlError error={error}/>;

  return <TeamSummary teamInsight={data.latestTeamInsight} className={className}/>;
}

export function TeamSummary({teamInsight, className}) {
  if (!teamInsight)
    return <Paragraph className={className}>Socrates needs more observations to provide insights.</Paragraph>;

  return <div className={className}>
    <Subheading>Your team in a nutshell</Subheading>
    <Markdown components={{
        p(props) {
            const {node, ...rest} = props;
            return <Paragraph {...rest} />;
        },
        ol(props) {
            const {node, ...rest} = props;
            return <List ordered {...rest} />;
        },
        ul(props) {
            const {node, ...rest} = props;
            return <List {...rest} />;
        }
    }}>{teamInsight.summary}</Markdown>
    <Paragraph className="text-right">
      <Timestamp ts={teamInsight.createdAt} />
    </Paragraph>
  </div>;
}
